import {
  MortgageLoanPurpose,
  Housing,
  Employment,
  MortgageQuestionsForm,
  MortgageQuestion,
  IncomeDeviate,
  PrivateLoanType,
  MaritalStatus
} from "src/api";

export const getQuestionValue = (
  question: MortgageQuestion,
  form: any,
  isCoApplicant: boolean,
  index: number
): any => {
  if (!question.field) {
    console.warn("getValue: question.field is missing", question);
    return null;
  }
  if(form.currentPropertyHasBeenSold == true) {
    form.agreeToPropertySellBySf = null;
  }
  if (question.forHousing) {
    return form.housings[index][question.field];
  }
  if (question.forPrivateLoans || question.forOwnedCars) {
    if (!form.privateLoans) return null;
    if (!form.privateLoans.length || form.privateLoans.length < index)
      return null;

    if (question.forOwnedCars) {
      
      if (form.privateLoans[index][question.field] === "Leased")
        form.privateLoans[index][question.field] = 0;

      if (form.privateLoans[index][question.field] === "Owned")
        form.privateLoans[index][question.field] = 1;

      index = form.privateLoans.findIndex(
        (loan: any) => loan.loanType === PrivateLoanType.Car
      );

      return form.privateLoans[index][question.field];
    }

    return form.privateLoans[index][question.field];
  }

  if (question.forApplicant) {
    if (isCoApplicant) return form.coApplicant[question.field];
    else return form.applicant[question.field];
  } else return form[question.field];
};

export const getRequiredQuestions = (
  state: any,
  form: MortgageQuestionsForm
): String[] => {
  let res: String[] = [];

  // if premium banks are present, we ignore specialist
  const withSpecialist = state.withPremium ? false : state.withSpecialist;

  const isCoApplicant = state.isCoApplicant;

  const applicantForm = isCoApplicant ? form.coApplicant : form.applicant;

  res = res.concat(["Q57"]);

  if (state.loanPurpose == MortgageLoanPurpose.Renegotiate) {
    res = res.concat(["Q54"]);
    if (
      form.renegotiateLoanPurpose?.length == 1 &&
      form.renegotiateLoanPurpose[0] != 0
    ) {
      res = res.concat(["Q66"]);
    } else if (
      form.renegotiateLoanPurpose != null &&
      form.renegotiateLoanPurpose.length > 1
    ) {
      res = res.concat(["Q66"]);
    }
    res = res.concat(["Q67"]);
  }

  // INCOME
  const maritalStatus = isCoApplicant
    ? state.coApplicantMaritalStatus
    : state.applicantMaritalStatus;
  const employment = isCoApplicant
    ? state.coApplicantEmployment
    : state.applicantEmployment;

  if (
    (maritalStatus == MaritalStatus.Married ||
      maritalStatus == MaritalStatus.Cohabitant) &&
    state.coApplicantMaritalStatus == null
  )
    res = res.concat(["Q70"]);

  if (employment == Employment.SelfEmployed) res = res.concat(["Q71"]);

  if (withSpecialist) {
    res = res.concat(["Q22"]);
    if (applicantForm?.hasOtherIncome) res = res.concat(["Q23", "Q24"]);

    if (form.needsDownPaymentLoan) res = res.concat(["Q36"]);
    if (form.needsBridgeLoan) res = res.concat(["Q38"]);
    if (form.howFarInPurchaseProcess == 4) res = res.concat(["Q40"]);
  }

  if (
    state.loanPurpose == MortgageLoanPurpose.Purchase ||
    state.loanPurpose == MortgageLoanPurpose.PromissoryLetter
  ) {
    res = res.concat(["Q35", "Q37", "Q39"]);
  }

  if (state.withPremium) {
    const employment = isCoApplicant
      ? state.coApplicantEmployment
      : state.applicantEmployment;

    if (employment == Employment.FullTimeEmployee)
      res = res.concat(["Q25", "Q26", "Q27"]);
    if (employment == Employment.SelfEmployed)
      res = res.concat(["Q28", "Q28b", "Q29", "Q30"]);
    if (employment == Employment.EarlyRetired) res = res.concat(["Q31"]);
    if (employment == Employment.Probationary)
      res = res.concat(["Q25", "Q26", "Q27", "Q32"]);
    if (employment == Employment.HourlyEmployee)
      res = res.concat(["Q25", "Q26", "Q27", "Q33"]);

    //Add applicant retirement income question if age is over 59 but not retired
    var today = new Date();
    var personalNumber = isCoApplicant? state.coApplicantPersonalNumber: state.applicantPersonalNumber;
    var parsedBornYear = parseInt(personalNumber.substring(0, 4));
    var applicantAge = parseInt(today.getFullYear().toString()) - parsedBornYear;
    if (applicantAge > 59 && employment != Employment.Retired && employment != Employment.EarlyRetired)
      res = res.concat(["Q68"]);
    
  }

  const incomeDeviates = isCoApplicant
    ? state.coApplicantIncomeDeviates
    : state.applicantIncomeDeviates;

  if (incomeDeviates != null) {
    var count = 0;
    incomeDeviates.forEach((element: IncomeDeviate) => {
      if (element.applicantIncomeDeviates) {
        switch (count) {
          case 0:
            res = res.concat(["Q34"]);
            break;
          case 1:
            res = res.concat(["Q34b"]);
            break;
        }
        count++;
      }
    });
  }

  // OBJECT
  if (state.loanPurpose == "Renegotiate" && state.withPremium) {
    if (state.propertyHousing == Housing.House)
      res = res.concat(["Q45", "Q46", "Q47", "Q48"]);
    if (state.propertyHousing == Housing.Condominium)
      res = res.concat([
        "Q45",
        "Q46",
        "Q47",
        "Q48",
        "Q49",
        "Q50",
        "Q51",
        "Q52"
      ]);
    if (state.propertyHousing == Housing.Cottage)
      res = res.concat(["Q45", "Q46", "Q47", "Q48", "Q53"]);
  }

  // only for prom.letter or purchase
  if (
    state.loanPurpose == MortgageLoanPurpose.Purchase ||
    state.loanPurpose == MortgageLoanPurpose.PromissoryLetter
  ) {
    // OTHER
    if (state.withPremium) {
      if (form.needsDownPaymentLoan) res = res.concat(["Q36"]);
      if (form.needsBridgeLoan) res = res.concat(["Q38"]);
      if (form.howFarInPurchaseProcess == 4) res = res.concat(["Q40"]);
    }

    if (state.downPayment) {
      // DOWNPAYMENT #1
      res = res.concat(["Q1a", "Q1b", "Q1c", "Q1d", "Q1e", "Q1f"]);

      // DOWNPAYMENT #2
      if (form.downPaymentFromSavings)
        res = res.concat(["Q2", "Q3", "Q4", "Q5"]);
      if (form.downPaymentFromHomeSale){
        res = res.concat(["Q6", "Q7", "Q8", "Q9", "Q69"]);
          if(form.currentPropertyHasBeenSold == false) res = res.concat(["Q72"]);
      }
       
      if (form.downPaymentFromHeritage) res = res.concat(["Q10"]);
      if (form.downPaymentFromGift) res = res.concat(["Q11", "Q12", "Q13"]);
      if (form.downPaymentFromLoan) {
        res = res.concat(["Q14"]);
        if (form.downPaymentFromOther) res = res.concat(["Q21", "Q64"]);
        if (form.needsToBorrowDownPayment) res = res.concat(["Q65"]);

        // DOWNPAYMENT #3
        const loanSources = form.downPaymentFromLoanSource ?? [];

        // a) loan source=Bank
        if (loanSources.indexOf(0) !== -1) res = res.concat(["Q15", "Q16"]);
        // b) any other loan source
        if (
          loanSources.length > 1 ||
          (loanSources.length == 1 && loanSources.indexOf(0) == -1)
        )
          res = res.concat(["Q15", "Q18", "Q19"]);
      }

      //   if (form.downPaymentFromLoanSource?.indexOf()) res = res.concat(["Q18"]);

      // TODO person
      // res = res.concat(["Q18"]);
    }
  }

  // // PRIVATE LOANS AND CREDITS
  // var privateLoans =
  //   form.privateLoans == null
  //     ? null
  //     : form.privateLoans.filter(
  //         (loan) => loan.loanType != PrivateLoanType.Car
  //       );
  // if (privateLoans && privateLoans.length && state.withPremium)
  //   res = res.concat(["Q41", "Q42"]);

  // res = res.concat(["Q55"]);
  // if (form.carsInHousehold) res = res.concat(["Q56"]);
  //   if (form.carOwnType === 0) res = res.concat(["Q56a"]);

  // HOUSING TO KEEP
  const housingsToKeep = form.housings?.filter((h) => h.keepCurrentHousing);
  if (housingsToKeep && housingsToKeep?.length > 0)
    res = res.concat(["Q59", "Q61", "Q62"]);
  //"Q60"

  // remove duplicates
  return [...new Set(res)];
};

// export const questions = questionsDictionary;
